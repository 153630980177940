import React, { useEffect } from "react";
import Layout from "components/Global/Layout";
import AmazonButton from "components/Global/AmazonButton";

const StoreLocatorPage = () => {
	useEffect(() => {
		console.log("new");
		let scriptElement;
		const loadStoreRocket = () => {
			scriptElement = document.createElement("script");
			scriptElement.src = "https://cdn.storerocket.io/widget.js";
			scriptElement.async = true;

			scriptElement.onload = () => {
				if (window.StoreRocket) {
					try {
						window.StoreRocket.init({
							selector: "#storerocket-store-locator",
							account: "kDJ3zkL4mn",
						});
					} catch (err) {
						console.error("StoreRocket initialization failed:", err);
					}
				}
			};

			document.body.appendChild(scriptElement);
		};

		loadStoreRocket();

		return () => {
			if (scriptElement && scriptElement.parentNode) {
				scriptElement.parentNode.removeChild(scriptElement);
			}
		};
	}, []);

	return (
		<Layout
			pageName="store-locator"
			amazon="https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas"
		>
			<div className="store-locator">
				<div className="store-locator-title-container">
					<h2>Store Locator</h2>
				</div>

				<div className="inner-store-locator-container">
					<div className="store-locator-title">
						<h3>Available nationwide on Amazon</h3>
						<AmazonButton to="https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas" />
					</div>

					<div
						id="storerocket-store-locator"
						className="store-locator-container"
					/>
				</div>
			</div>
		</Layout>
	);
};

export default StoreLocatorPage;
